import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BoldTitle from "src/components/Displays/BoldTitle";
import FormHeader from "src/components/Displays/FormHeader";
import LabelTitle from "src/components/Displays/LabelTitle";
import PageTitle from "src/components/Displays/PageTitle";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";
import Page from "src/components/Layout/Page";
import PrimaryButton from "src/components/Shared/PrimaryButton";
import {
  SESSION_KEYS,
  USER_PROFILE_DATA,
  FUNNEL_ORDER_RESPONSE,
} from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";

const Conclusion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getSessionStorageItem] = useSessionStorage<USER_PROFILE_DATA>(
    SESSION_KEYS.USER_PROFILE_DATA
  );
  const sessionData = getSessionStorageItem();

  const [getFunnelOrderResponse] = useSessionStorage<FUNNEL_ORDER_RESPONSE>(
    SESSION_KEYS.FUNNEL_ORDER_RESPONSE
  );

  const [getTariffs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);

  const handleFinishClick = () => {
    let redirectUrl = getFunnelOrderResponse()?.redirectUrl;

    if (redirectUrl && redirectUrl !== "") {
      window.location.href = redirectUrl;
      return;
    }
    navigate("/");
  };

  return (
    <Page pageTitle="" pageSubTitle="">
      <Stack
        gap={4}
        sx={{
          mt: { xs: 8, sm: 0 },
        }}
      >
        <BoldTitle text="🥳" />

        <PageTitle
          text={t("page.conclusion.title", {
            name: getTariffs()?.[0]?.tariffDisplayName || "",
            tarif: getTariffs()?.[0]?.tariffProviderDisplayName || "",
          })}
        />

        <ProgressBarTitle
          fontWeight="700"
          text={t("page.conclusion.subtitle1", {
            name: getTariffs()?.[0]?.tariffDisplayName || "",
            tarif: getTariffs()?.[0]?.tariffProviderDisplayName || "",
          })}
        />

        <ProgressBarTitle
          text={t("page.conclusion.subtitle2", {
            name: getTariffs()?.[0]?.tariffDisplayName || "",
            tarif: getTariffs()?.[0]?.tariffProviderDisplayName || "",
          })}
        />

        <Stack
          sx={(theme) => ({
            borderRadius: 2,
            py: 2,
            px: 3,
            backgroundColor: theme.palette.green[100],
          })}
        >
          <LabelTitle
            text={t("page.conclusion.email", {
              tarif: getTariffs()?.[0]?.tariffProviderDisplayName || "",
            })}
          />
          <FormHeader
            sx={(theme) => ({
              color: theme.palette.green[50],
            })}
            text={sessionData?.email || ""}
          />
        </Stack>

        <PrimaryButton
          sx={(theme) => ({
            mt: 4,
            bgcolor: theme.palette.secondaryButton.background,
            color: theme.palette.secondaryButton.text,
            "&:hover": {
              backgroundColor: theme.palette.secondaryButton.background,
            },
          })}
          onClick={handleFinishClick}
          text={t("page.conclusion.button")}
        />
      </Stack>
    </Page>
  );
};

export default Conclusion;
