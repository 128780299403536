import Page from "src/components/Layout/Page";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Collapse } from "@mui/material";
import FormContainer from "src/components/Displays/FormContainer";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addressInputsFormSchema,
  deliveryAddressInputsFormSchema,
} from "src/schema/addressInputsFormSchema";
import AddressInputsForm from "src/components/Forms/AddressInputsForm";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { ROUTES } from "src/consts/routes";
import {
  SESSION_KEYS,
  SITE_TECHNICAL_DATA,
  USER_PROFILE_DATA,
} from "src/consts/session";
import { useEffect, useState } from "react";
import AddressInputsDeliveryAddressForm from "src/components/Forms/AddressInputsDeliveryAddressForm";
import CheckboxInput from "src/components/Inputs/CheckboxInput";

export interface AddressFormValues {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;

  useDeliveryAddressChecked?: boolean;

  streetDeliveryAddress?: string | null;
  houseNumberDeliveryAddress?: string | null;
  zipCodeDeliveryAddress?: string | null;
  cityDeliveryAddress?: string | null;
}

const Address = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.address.formTitle");
  const formSubTitle = t("page.address.formSubTitle");
  const pageTitle = t("page.address.pageTitle");
  const pageSubTitle = t("page.address.pageSubTitle");
  const deliveryAddressInputLabelText = t(
    "page.address.formFields.deliveryAddressCheck.inputLabelText"
  );
  const deliveryAddressCheckboxLabelText = t(
    "page.address.formFields.deliveryAddressCheck.checkboxLabelText"
  );

  const [schema, setSchema] = useState(addressInputsFormSchema);

  const [userProfileData, setUserProfileData] =
    useSessionStorage<USER_PROFILE_DATA>(SESSION_KEYS.USER_PROFILE_DATA);

  const [siteTechnicalData] = useSessionStorage<SITE_TECHNICAL_DATA>(
    SESSION_KEYS.SITE_TECHNICAL_DATA
  );

  const {
    watch,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
  } = useForm<AddressFormValues>({
    defaultValues: {
      street: "",
      houseNumber: "",
      zipCode: "",
      city: "",

      useDeliveryAddressChecked: false,
    },
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const profileData = userProfileData();
    const technicalData = siteTechnicalData();

    reset({
      ...profileData,
      street: profileData?.street,
      houseNumber: profileData?.houseNumber,
      zipCode: technicalData?.pvSetup?.postalCode,
      city: profileData?.city,

      useDeliveryAddressChecked:
        profileData?.useDeliveryAddressChecked === "true" ? true : false,

      streetDeliveryAddress: profileData?.streetDeliveryAddress,
      houseNumberDeliveryAddress: profileData?.streetNumberDeliveryAddress,
      zipCodeDeliveryAddress: profileData?.zipCodeDeliveryAddress,
      cityDeliveryAddress: profileData?.cityDeliveryAddress,
    });

    setSchema(
      profileData?.useDeliveryAddressChecked === "true"
        ? deliveryAddressInputsFormSchema
        : addressInputsFormSchema
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "useDeliveryAddressChecked") {
        setSchema(
          value.useDeliveryAddressChecked
            ? deliveryAddressInputsFormSchema
            : addressInputsFormSchema
        );

        setValue("streetDeliveryAddress", value.street);
        setValue("houseNumberDeliveryAddress", value.houseNumber);
        setValue("zipCodeDeliveryAddress", value.zipCode);
        setValue("cityDeliveryAddress", value.city);
        clearErrors();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, reset, clearErrors, getValues, setValue]);

  const onSubmit = (data: AddressFormValues) => {
    const sessionData = userProfileData();

    let streetDeliveryAddress = data.street;
    let streetNumberDeliveryAddress = data.houseNumber;
    let zipCodeDeliveryAddress = data.zipCode;
    let cityDeliveryAddress = data.city;

    if (data.useDeliveryAddressChecked) {
      streetDeliveryAddress = data.streetDeliveryAddress ?? "";
      streetNumberDeliveryAddress = data.houseNumberDeliveryAddress ?? "";
      zipCodeDeliveryAddress = data.zipCodeDeliveryAddress ?? "";
      cityDeliveryAddress = data.cityDeliveryAddress ?? "";
    }

    const updatedSessionData: USER_PROFILE_DATA = {
      ...sessionData,
      street: data.street,
      houseNumber: data.houseNumber,
      zipCode: data.zipCode,
      city: data.city,

      useDeliveryAddressChecked: data.useDeliveryAddressChecked?.toString(),

      streetDeliveryAddress,
      streetNumberDeliveryAddress,
      zipCodeDeliveryAddress,
      cityDeliveryAddress,
    };
    setUserProfileData(updatedSessionData);
    navigate(ROUTES.TECHNICAL_DETAILS);
  };

  const handleBackClick = () => {
    navigate(ROUTES.CONTACT_INFORMATION);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer
        title={formTitle}
        subTitle={formSubTitle}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 6,
            marginBottom: 3,
            gap: 5,
          }}
        >
          <AddressInputsForm control={control} />
          <CheckboxInput
            control={control}
            name="useDeliveryAddressChecked"
            inputLabelText={deliveryAddressInputLabelText}
            checkboxLabelText={deliveryAddressCheckboxLabelText}
          />
          <Collapse in={getValues().useDeliveryAddressChecked}>
            <AddressInputsDeliveryAddressForm control={control} />
          </Collapse>
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default Address;
