import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EarnWidget, PayWidget } from "src/assets";
import BoldSubtitle from "src/components/Displays/BoldSubtitle";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import Text from "src/components/Displays/Text";
import TextSubtitle from "src/components/Displays/TextSubtitle";
import StartCalculatorModal from "src/components/Modal/StartCalculatorModal";
import PrimaryButton from "src/components/Shared/PrimaryButton";
import TabActive from "src/components/TabActive/TabActive";
import { ROUTES } from "src/consts/routes";
import { useToggle } from "src/hooks/useToggle";

const Content = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, toggleModal] = useToggle(false);
  const [payButtonActive, togglePayButtonActive] = useToggle(true);
  const [earnButtonActive, toggleEarnButtonActive] = useToggle(false);

  const [searchParams] = useSearchParams();
  const tid = searchParams.get("tid") ?? "";
  const spid = searchParams.get("spid") ?? "";

  const isStageOrProdEnv =
    process.env.REACT_APP_BUILD_ENV === "STAGE" ||
    process.env.REACT_APP_BUILD_ENV === "PROD";

  const showOverlay =
    (tid.length === 0 || spid.length === 0) && isStageOrProdEnv;

  const handleStartCalculator = () => {
    if (showOverlay) {
      toggleModal();
      return;
    }
    navigate(ROUTES.PV_SETUP);
  };

  const handleModalSubmit = () => {
    const paPoSignUpUrl = process.env.REACT_APP_PAPO_SIGN_UP_URL;
    if (paPoSignUpUrl) window.open(paPoSignUpUrl, "_blank");
  };

  return (
    <Stack
      gap={6}
      sx={{
        width: "100%",
        maxWidth: "880px",
      }}
    >
      <PrimaryButton
        onClick={handleStartCalculator}
        sx={{
          alignSelf: "start",
          position: "sticky",
          top: 10,
          display: { xs: "none", sm: "block" },
          textTransform: "none",
        }}
        text={t("page.introduction.startTarifCalculator")}
      />

      <BoldSubtitle text={t("page.introduction.textTitle")} />

      <Stack gap={6}>
        <TextSubtitle
          sx={{
            fontSize: { xs: "16px", sm: "20px" },
            fontWeight: { xs: 500, sm: 400 },
            lineHeight: { xs: "22px", sm: "30px" },
          }}
          text={t("page.introduction.textSubtitleOne")}
        />
        <Stack>
          <TextSubtitle
            sx={{
              fontSize: { xs: "16px", sm: "20px" },
              fontWeight: { xs: 500, sm: 400 },
              lineHeight: { xs: "22px", sm: "30px" },
            }}
            text={t("page.introduction.textSubtitleTwo")}
          />
          <TextSubtitle
            sx={{
              fontSize: { xs: "16px", sm: "20px" },
              fontWeight: { xs: 500, sm: 400 },
              lineHeight: { xs: "22px", sm: "30px" },
            }}
            text={t("page.introduction.textSubtitleThree")}
          />
        </Stack>
      </Stack>

      <Stack width={"100%"} flexDirection="row" gap={{ xs: 0, sm: 6 }}>
        <TabActive
          isActive={payButtonActive}
          toggleActive={() => {
            if (payButtonActive) return;
            togglePayButtonActive();
            if (earnButtonActive) toggleEarnButtonActive();
          }}
        >
          <Text
            fontWeight={700}
            color={
              payButtonActive
                ? theme.palette.tabActive.text
                : theme.palette.tabInactive.text
            }
            text={t("page.introduction.payButtonTitle")}
          />
          <PageSubTitle
            color={
              payButtonActive
                ? theme.palette.tabActive.text
                : theme.palette.tabInactive.text
            }
            text={t("page.introduction.payButtonSubtitle")}
          />
        </TabActive>

        <TabActive
          isActive={earnButtonActive}
          toggleActive={() => {
            if (earnButtonActive) return;
            toggleEarnButtonActive();
            if (payButtonActive) togglePayButtonActive();
          }}
        >
          <Text
            fontWeight={700}
            color={
              earnButtonActive
                ? theme.palette.tabActive.text
                : theme.palette.tabInactive.text
            }
            text={t("page.introduction.earnButtonTitle")}
          />
          <PageSubTitle
            color={
              earnButtonActive
                ? theme.palette.tabActive.text
                : theme.palette.tabInactive.text
            }
            text={t("page.introduction.earnButtonSubtitle")}
          />
        </TabActive>
      </Stack>

      <Stack width={"100%"}>
        {earnButtonActive && (
          <EarnWidget
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        )}
        {payButtonActive && (
          <PayWidget
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </Stack>

      <PrimaryButton
        onClick={handleStartCalculator}
        sx={{
          width: "100%",
          alignSelf: "start",
          position: "sticky",
          bottom: 10,
          display: { xs: "block", sm: "none" },
          textTransform: "none",
        }}
        text={t("page.introduction.startTarifCalculator")}
      />

      <StartCalculatorModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={handleModalSubmit}
        title={t("page.introduction.modal.title")}
        subtitle1={t("page.introduction.modal.subtitle1")}
        subtitle2={t("page.introduction.modal.subtitle2")}
        subtitle3={t("page.introduction.modal.subtitle3")}
        submitButtonText={t("page.introduction.modal.submitButtonText")}
      />
    </Stack>
  );
};

export default Content;
