import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/consts/routes";
import TarifSection from "../Tarif/components/TarifSection";
import { useMemo, useState } from "react";
import { useToggle } from "src/hooks/useToggle";
import { Box, Card, Stack } from "@mui/material";
import CompanyDetails from "src/components/GlobalContext/CompanyDetails";
import PageTitle from "src/components/Displays/PageTitle";
import InputLabel from "src/components/Displays/InputLabel";
import Text from "src/components/Displays/Text";
import { useTranslation } from "react-i18next";
import { CancelContractTypes } from "../ContractChange";
import { PaymentDetailsTypes } from "../PaymentDetails";
import useGetContractSummaryData from "./useGetContractSummaryData";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  contractSummaryWithSmartMeterFormSchema,
  contractSummaryWithoutSmartMeterFormSchema,
} from "src/schema/contractSummaryFormSchema";
import ContractSummaryForm from "src/components/Forms/ContractSummaryForm";
import anonymizeIBAN from "src/helpers/anonymizeIBAN";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";
import {
  SESSION_KEYS,
  FUNNEL_ORDER_RESPONSE,
  SITE_TECHNICAL_DATA,
} from "src/consts/session";
import { dateFormat } from "src/helpers/dateFormat";
import usePayloadMapped from "src/dto/usePayloadMapped";
import { funnelOrder, FunnelOrderPayload } from "src/api/apiClients";
import { SmartMeterTypes } from "../SmartMeter";
import { MSG_IDS } from "src/consts/msgIds";

export interface ContractSummaryFormValues {
  wallet: boolean;
  smartMeter?: boolean;
}

const ContractSummary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOverlayOpen, toggleIsOverlayOpen] = useToggle(false);
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    siteTechnicalData: siteTechnicalDataFromStorage,
    userProfileData,
    contractDetailData,
  } = usePayloadMapped();

  const [getSessionStorageItem] = useSessionStorage<Tariff[]>(
    SESSION_KEYS.TARIFFS
  );

  const [, setFunnelOrderResponse] = useSessionStorage<FUNNEL_ORDER_RESPONSE>(
    SESSION_KEYS.FUNNEL_ORDER_RESPONSE
  );

  const { tariffsData } = useMemo(() => {
    const tariffsData = getSessionStorageItem();
    return {
      tariffsData: tariffsData,
    };
  }, [getSessionStorageItem]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSubmit = async () => {
    const payload: FunnelOrderPayload = {
      siteTechnicalData: {
        pvInstalled: siteTechnicalDataFromStorage.pvInstalled,
        pvPowerMaxKwp: siteTechnicalDataFromStorage.pvPowerMaxKwp,
        wallboxInstalled: siteTechnicalDataFromStorage.wallboxInstalled,
        batteryInstalled: siteTechnicalDataFromStorage.batteryInstalled,
        batterySizeKwh: siteTechnicalDataFromStorage.batterySizeKwh,
        smartMeterInstalled: siteTechnicalDataFromStorage.smartMeterInstalled,
      },

      userProfileData: {
        yearlyGridSupplyKwh: siteTechnicalDataFromStorage.yearlyGridSupplyKwh,
        zipcode: userProfileData.zipcode,
        firstname: userProfileData.firstname,
        lastname: userProfileData.lastname,
        country: userProfileData.country,
        city: userProfileData.city,
        street: userProfileData.street,
        streetNumber: userProfileData.streetNumber,
        emailAddress: userProfileData.emailAddress,
        mobilePhone: userProfileData.mobilePhone,
        invoiceStreet: userProfileData.invoiceStreet,
        invoiceStreetNumber: userProfileData.invoiceStreetNumber,
        invoiceZipcode: userProfileData.invoiceZipcode,
        invoiceCity: userProfileData.invoiceCity,
      },

      contractDetailData: {
        utilityToCancelExistingContract:
          contractDetailData.utilityToCancelExistingContract,
        newContractStartDate: contractDetailData.newContractStartDate,
        energyProviderUuid: contractDetailData.energyProviderUuid,
        paymentMethod: contractDetailData.paymentMethod,
        bankAccountHolder: contractDetailData.bankAccountHolder,
        iban: contractDetailData.iban,
        agbSmartMeterAccepted: true,
        agbEnergyProviderAccepted: true,
        tariffLogicalId: contractDetailData.tariffLogicalId,
      },
    };

    setIsLoading(true);

    try {
      const response = await funnelOrder(payload);
      setTimeout(() => {
        setIsLoading(false);
        navigate(ROUTES.CONCLUSION);
      }, 1000);

      setFunnelOrderResponse({
        msgId: response.msgId ?? "",
        redirectUrl: response.redirectUrl ?? "",
      });
    } catch (e) {
      setIsLoading(false);
      const error = e as Error;
      setFunnelOrderResponse({
        msgId: error.msgId ?? "",
        redirectUrl: error.redirectUrl ?? "",
      });

      switch (error.status) {
        case 400:
          navigate(`${ROUTES.ERROR}?msgId=${MSG_IDS.funnel_order_400}`);
          break;
        default:
          navigate(ROUTES.ERROR);
          break;
      }
    }
  };

  const handleBack = () => {
    navigate(ROUTES.PAYMENT_DETAILS);
  };

  const [technicalData] = useSessionStorage<SITE_TECHNICAL_DATA>(
    SESSION_KEYS.SITE_TECHNICAL_DATA
  );
  const isSmartMeterSelected =
    technicalData()?.smartMeterInstalled === SmartMeterTypes.JA;

  const form = useForm<ContractSummaryFormValues>({
    mode: "onSubmit",
    defaultValues: {
      smartMeter: false,
      wallet: false,
    },
    resolver: yupResolver(
      isSmartMeterSelected
        ? contractSummaryWithSmartMeterFormSchema
        : contractSummaryWithoutSmartMeterFormSchema
    ),
  });

  const {
    tariffProviderDisplayName,
    tariffDisplayName,
    street,
    houseNumber,
    zipCode,
    city,
    mobileNumber,
    email,
    marketLocationID,
    meterNumber,
    cancelContractType,
    energyProvider,
    contractStartDate,
    paymentAccout,
    cardHolder,
    iban,
  } = useGetContractSummaryData();

  return (
    <>
      <Stack
        gap={20}
        sx={{
          mt: { xs: 8, sm: 0 },
        }}
      >
        <PageTitle text={t("page.contractSummary.doneBusiness")} />
        <CompanyDetails
          by={t("globalContext.by")}
          tariffDisplayName={tariffDisplayName}
          tariffProviderDisplayName={tariffProviderDisplayName}
        />
      </Stack>

      <TarifSection
        expanded={expanded}
        handleChange={handleChange}
        isOverlayOpen={isOverlayOpen}
        toggleIsOverlayOpen={toggleIsOverlayOpen}
        tariffsData={tariffsData}
      />

      <Box height={12} />

      <Card
        sx={(theme) => ({
          borderRadius: 2,
          background: theme.palette.form.background,
          boxShadow: `0px 1px 2px 0px ${theme.palette.grey[500]}`,
          p: 3,
        })}
      >
        <Stack gap={4}>
          <Stack gap={2}>
            <InputLabel
              text={t("page.contractSummary.newEnergyProductTitle")}
            />
            <Text
              text={t("page.contractSummary.newEnergyProduct", {
                tariffDisplayName,
                tariffProviderDisplayName,
              })}
            />
          </Stack>

          <Stack>
            <Stack gap={2}>
              <InputLabel text={t("page.contractSummary.deliveryAddress")} />
              <Text
                text={t("page.contractSummary.deliveryAddressDetails1", {
                  street,
                  houseNumber,
                })}
              />
            </Stack>
            <Text
              text={t("page.contractSummary.deliveryAddressDetails2", {
                zipCode,
                city,
              })}
            />
          </Stack>

          <Stack>
            <Stack gap={2}>
              <InputLabel
                text={t("page.contractSummary.contactInformationHeader")}
              />
              <Text text={mobileNumber} />
            </Stack>
            <Text text={email} />
          </Stack>

          <Stack>
            <Stack gap={2}>
              <InputLabel text={t("page.contractSummary.marketLocationId")} />
              <Text text={marketLocationID} />
            </Stack>
          </Stack>

          <Stack>
            <Stack gap={2}>
              <InputLabel text={t("page.contractSummary.meterNumber")} />
              <Text text={meterNumber} />
            </Stack>
          </Stack>

          <Stack>
            <Stack gap={2}>
              <InputLabel
                text={t("page.contractSummary.terminationOfContract")}
              />
              <Text
                text={
                  cancelContractType === CancelContractTypes.AUTO
                    ? t("page.contractSummary.byUtility", { energyProvider })
                    : t("page.contractSummary.byUser")
                }
              />
            </Stack>
          </Stack>

          <Stack>
            <Stack gap={2}>
              <InputLabel
                text={t("page.contractSummary.startElectricityContract")}
              />
              <Text text={dateFormat(contractStartDate)} />
            </Stack>
          </Stack>

          <Stack>
            <Stack gap={2}>
              <InputLabel text={t("page.contractSummary.paymentMethod")} />
              {paymentAccout === PaymentDetailsTypes.SEPA ? (
                <Text text={cardHolder} />
              ) : (
                <Text text={t("page.contractSummary.bankTransfer")} />
              )}
            </Stack>
            {paymentAccout === PaymentDetailsTypes.SEPA && (
              <Text text={anonymizeIBAN(iban)} />
            )}
          </Stack>

          <Box
            component="form"
            autoComplete="off"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <ContractSummaryForm
              form={form}
              isSmartMeterSelected={isSmartMeterSelected}
            />
            <FormSubmitContainer
              onClick={handleBack}
              isLoading={isLoading}
              submitButtonText={t("page.contractSummary.submitButtonText")}
            />
          </Box>
        </Stack>
      </Card>
    </>
  );
};

export default ContractSummary;
