import { getBaseUrl } from "./getBaseUrl";

interface CallApi {
  path: string;
  method?: string;
  payload?: any;
  transferId?: string;
  energyServiceFlag?: string;
}

interface GetOptions {
  method: string;
  payload: string;
  transferId?: string;
  energyServiceFlag?: string;
}

export class ErrorBase extends Error {
  msgId: string;
  status?: number;
  redirectUrl: string;

  constructor(
    message: string,
    {
      msgId,
      status,
      redirectUrl,
    }: {
      msgId: string;
      status?: number;
      redirectUrl: string;
    }
  ) {
    super(message);
    this.msgId = msgId;
    this.status = status;
    this.name = "ErrorBase";
    this.redirectUrl = redirectUrl;
  }
}

const getOptions = ({
  method,
  payload,
  transferId,
  energyServiceFlag,
}: GetOptions) => {
  const headers = new Headers({ "Content-Type": "application/json" });

  if (transferId) {
    headers.append("TRANSFER-ID", transferId);
  }

  if (energyServiceFlag) {
    headers.append("Energy-Service-Flag", energyServiceFlag);
  }

  return !payload
    ? {
        method,
        headers,
      }
    : {
        method,
        headers,
        body: JSON.stringify(payload),
      };
};

export const callApi = async <T>({
  path,
  method = "GET",
  payload,
  transferId,
  energyServiceFlag,
}: CallApi): Promise<T> => {
  const url = getBaseUrl(path);

  try {
    const options = getOptions({
      method,
      payload,
      transferId,
      energyServiceFlag,
    });
    const response = await fetch(url, options);

    if (!response.ok) {
      const errorJson = await response.json();
      throw new ErrorBase(
        errorJson.message ? errorJson.message : response.statusText,
        {
          msgId: errorJson.msgId,
          status: response.status,
          redirectUrl: errorJson.redirectUrl,
        }
      );
    } else {
      const responseStr = await response.text();
      const responseJson = responseStr === "" ? {} : JSON.parse(responseStr);
      return responseJson;
    }
  } catch (e) {
    const error = e as Error;
    throw error;
  }
};
