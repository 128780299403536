import { Info } from "src/assets";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useToggle } from "src/hooks/useToggle";
import { Box, Grid, Stack } from "@mui/material";
import { AddressFormValues } from "src/pages/Address";
import TextInput from "src/components/Inputs/TextInput";
import InputWrapper from "src/components/Inputs/InputWrapper";
import ConfirmationModal from "src/components/Modal/ConfirmationModal";

interface AddressInputsFormProps {
  control: Control<AddressFormValues>;
}

const AddressInputsForm = ({ control }: AddressInputsFormProps) => {
  const { t } = useTranslation();
  const streetLabel = t("page.address.formFields.street.label");
  const houseNumberLabel = t("page.address.formFields.houseNumber.label");
  const zipCodeLabel = t("page.address.formFields.zipCode.label");
  const cityLabel = t("page.address.formFields.city.label");

  const [isOverlayOpen, toggleIsOverlayOpen] = useToggle(false);

  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextInput
              fullWidth
              control={control}
              name="street"
              labelText={streetLabel}
            />
          </Grid>

          <Grid item sm={6}>
            <TextInput
              fullWidth
              control={control}
              name="houseNumber"
              labelText={houseNumberLabel}
            />
          </Grid>
        </Grid>

        <Box height={20} />

        <Stack direction={"row"} gap={3}>
          <InputWrapper maxWidth={"120px"}>
            <TextInput
              disabled
              name="zipCode"
              control={control}
              labelText={zipCodeLabel}
              labelEndAdornment={
                <Stack
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={toggleIsOverlayOpen}
                >
                  <Info />
                </Stack>
              }
            />
          </InputWrapper>

          <InputWrapper width={"265px"}>
            <TextInput
              fullWidth
              control={control}
              name="city"
              labelText={cityLabel}
            />
          </InputWrapper>
        </Stack>
      </Box>

      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Stack direction={"row"} gap={3}>
          <InputWrapper width={"100%"}>
            <TextInput
              fullWidth
              control={control}
              name="street"
              labelText={streetLabel}
            />
          </InputWrapper>

          <InputWrapper maxWidth={"120px"}>
            <TextInput
              control={control}
              name="houseNumber"
              labelText={houseNumberLabel}
            />
          </InputWrapper>
        </Stack>

        <Box height={20} />

        <Stack direction={"row"} gap={3}>
          <InputWrapper maxWidth={"120px"}>
            <TextInput
              control={control}
              name="zipCode"
              labelText={zipCodeLabel}
              labelEndAdornment={
                <Stack
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={toggleIsOverlayOpen}
                >
                  <Info />
                </Stack>
              }
            />
          </InputWrapper>

          <InputWrapper width={"100%"}>
            <TextInput
              fullWidth
              control={control}
              name="city"
              labelText={cityLabel}
            />
          </InputWrapper>
        </Stack>
      </Box>

      <ConfirmationModal
        isOpen={isOverlayOpen}
        onClose={toggleIsOverlayOpen}
        onSubmit={toggleIsOverlayOpen}
        subtitle={t("page.address.modal.zipCode.subTitle")}
        submitButtonText={t("page.address.modal.zipCode.confirm")}
      />
    </>
  );
};

export default AddressInputsForm;
