import { Modal as MuiModal, Stack } from "@mui/material";
import PrimaryButton from "../Shared/PrimaryButton";
import Text from "../Displays/Text";

interface Props {
  title?: string;
  subtitle: string;
  isOpen: boolean;
  closeButtonText?: string;
  submitButtonText: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmationModal = ({
  onClose,
  title,
  subtitle,
  closeButtonText,
  submitButtonText,
  isOpen,
  onSubmit,
}: Props) => {
  return (
    <MuiModal
      disableAutoFocus
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "16px",
        alignItems: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        justifyContent="space-between"
        gap={2}
        sx={{
          bgcolor: "#fff",
          width: "100%",
          maxWidth: "335px",
          px: 4,
          py: 5,
          borderRadius: "14px",
        }}
      >
        <Stack
          sx={{
            gap: 2,
          }}
        >
          {title && <Text text={title} />}
          <Text text={subtitle} />
        </Stack>
        <Stack
          sx={{
            mt: 4,
          }}
          gap={4}
          flexDirection="row"
          flexWrap="wrap"
        >
          {closeButtonText && (
            <PrimaryButton
              variant="text"
              sx={{
                p: 0,
                backgroundColor: "transparent",
                color: "#111",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={onClose}
              text={closeButtonText}
            />
          )}

          <PrimaryButton
            sx={{
              flex: 1,
            }}
            onClick={onSubmit}
            text={submitButtonText}
          />
        </Stack>
      </Stack>
    </MuiModal>
  );
};

export default ConfirmationModal;
