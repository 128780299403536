import { Typography, styled } from "@mui/material";
import { CSSProperties } from "react";

interface PageSubTitleProps {
  text?: string;
  color?: string;
  fontWeight?: CSSProperties["fontWeight"];
}

const PageSubTitle = ({ text, color, fontWeight }: PageSubTitleProps) => {
  const PageSubTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.primaryFont,
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: fontWeight || "400",
    lineHeight: "18px",
    whiteSpace: "pre-wrap",
  }));

  return <PageSubTitleStyles>{text}</PageSubTitleStyles>;
};

export default PageSubTitle;
