import { IconButton, Modal as MuiModal, Stack } from "@mui/material";
import PrimaryButton from "../Shared/PrimaryButton";
import { Close } from "src/assets";
import Text from "../Displays/Text";

interface StartCalculatorModalProps {
  title: string;
  subtitle1: string;
  subtitle2: string;
  subtitle3: string;
  isOpen: boolean;
  submitButtonText: string;
  onClose: () => void;
  onSubmit: () => void;
}

const StartCalculatorModal = ({
  onClose,
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  submitButtonText,
  isOpen,
  onSubmit,
}: StartCalculatorModalProps) => {
  return (
    <MuiModal
      disableAutoFocus
      open={isOpen}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        gap={6}
        sx={{
          m: 4,
          px: 4,
          py: 5,
          bgcolor: "#fff",
          width: "600px",
          minWidth: "285px",
          borderRadius: "14px",
        }}
      >
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text text={title} fontWeight={"bold"} />
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={(theme) => ({
                color: theme.palette.grey[500],
              })}
            >
              <Close />
            </IconButton>
          </Stack>

          <Text text={subtitle1} />
          <Text text={subtitle2} />
          <Text text={subtitle3} />
        </Stack>
        <Stack gap={4} flexDirection="row" flexWrap="wrap">
          <PrimaryButton
            fullWidth
            sx={{
              textTransform: "none",
            }}
            onClick={onSubmit}
            text={submitButtonText}
          />
        </Stack>
      </Stack>
    </MuiModal>
  );
};

export default StartCalculatorModal;
